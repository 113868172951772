import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Seo from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"
import Question from "./Question"

const FaqsPageWrapper = styled(PageWrapper)`
  padding-top: 5rem;

  color: ${props => props.theme.color.primary};
`
const NarrowRow = styled(Row)`
  margin-bottom: 3rem;
  max-width: 700px;

  text-align: center;

  a {
    text-decoration: underline;
  }
`

const FaqsPage = () => (
  <FaqsPageWrapper>
    <Seo title="FAQs" />
    <NarrowRow>
      <h1>f'real FAQs</h1>
      <p>
        Have questions about f'real drinks, our company, or our services? Take a
        look at the below Frequently Asked Questions!
      </p>
      <p>
        <strong>
          Still have questions? Feel free to{" "}
          <Link to="/contact">reach out</Link> to our team.
        </strong>
      </p>
    </NarrowRow>

    <Row>
      <Question title="What is f'real?" active={true}>
        <p>
          We are the folks who proudly bring you the BEST TASTING milkshakes,
          smoothies, and shakes blended by YOU in our magical milkshake machine.
          We don't scrimp on ingredients – real milk, fruit, and coffee. You can
          find f'real in over 20,000 locations around the world at convenience
          stores, college &amp; universities, theaters, supermarkets, and
          military bases.
        </p>
      </Question>
      <Question title="Can I rent a blender or buy one for my home?">
        <p>
          While we don't currently offer rental equipment or a blender for your
          home, we do get this request a lot and with any luck we'll have
          something in the near future. Don't forget to follow us on{" "}
          <a
            href="https://www.instagram.com/therealfreal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          {` `}
          so that you'll be the first to find out when we do!
        </p>
      </Question>
      <Question title="How is the blender cleaned?">
        <p>
          The cleaning process depends on the blender model. The blenders with
          the LCD touch screens are internally 100% self-sanitizing. The only
          part of those blenders that need to be wiped down each day is the
          outside surface. The fully automated internal sanitizing cycle runs
          automatically every 24 hours.
        </p>
      </Question>
      <Question title="What is the f'real allergen statement? Are all f'real products peanut-free?">
        <p>
          The Reese's Peanut Butter Cup f'real is the only flavor that contains
          peanuts; however there is an allergen statement printed on every cup
          stating that our products may contain traces of peanuts, tree nuts,
          soybeans, wheat, or eggs from manufacturing or blending since all of
          our products are made at the same manufacturer and share the same
          blender.
        </p>
      </Question>
      <Question title="How much should I expect to pay for a f'real milkshake?">
        <p>
          The stores have the freedom to choose how much they need to sell the
          products for. While the average price is currently around $3-4, you
          may find a particular location that sells them lower or higher than
          others around you, so be sure to check around!
        </p>
      </Question>
      <Question title="Are f'real products gluten-free?">
        <p>
          The OREO® Cookies & Cream f'real is the only flavor that contains
          wheat flour. That said, there is an allergen statement printed on
          every cup because there is a chance that other flavors could contain
          traces of wheat flour (or other allergens) since all of our products
          are made at the same manufacturer and share the same blender.
        </p>
      </Question>
      <Question title="Where can I find f'real products and blenders? Are you international?">
        <p>
          While a majority of f'real retailers are based in the US and Canada,
          you can find us all over the world! Kind of like Carmen San Diego.
          Check out our f'real locator to find the location nearest you.
        </p>
      </Question>
    </Row>
  </FaqsPageWrapper>
)

export default FaqsPage
